import React, { Component } from "react";
import { connect } from "react-redux";
import { getGifs } from "../../store/actions";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";

import Gif from "../gif.js/Gif";

import "./GifList.scss";
import { createRef } from "react";

class List extends Component {
  constructor(props) {
    super(props);
    this.listScroll = createRef();
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScrolln = event => {
    if (
      !this.props.gifs.data ||
      (this.props.gifs.data && !this.props.gifs.data.length)
    )
      return;
    const scroll = event.target.scrollTop;
    const maxHeight =
      (this.props.gifs.data[this.props.gifs.data.length - 1] &&
        this.props.gifs.data[this.props.gifs.data.length - 1].fromTop) ||
      Infinity;

    if (scroll >= maxHeight - event.target.clientHeight) this.props.getGifs();
  };

  render() {
    return (
      // <div
      //   className="list_wrpr"
      //   style={{ width: `${this.props.fitsInPage * 200}px` }}
      //   onScroll={() => this.handleScrolln()}
      //   ref={this.listScroll}
      // >

      <OverlayScrollbarsComponent
        style={{ width: `${this.props.fitsInPage * 200}px` }}
        onScroll={e => this.handleScrolln(e)}
        className="list_wrpr"
        ref={this.listScroll}
        options={{ scrollbars: { autoHide: "scroll" } }}
        extensions={["extensionA", "extensionB"]}
      >
        {" "}
        <div className="df jcc fww">
          {this.props.gifs.data && this.props.gifs.data.length ? (
            this.props.gifs.data.map((gif, i) => (
              <Gif
                key={i}
                i={i}
                gif={gif}
                updateDims={this.props.updateDims}
              ></Gif>
            ))
          ) : (
            <>
              {this.props.gifs && this.props.gifs.error && this.props.gifs.term
                ? this.props.gifs.error
                : !this.props.gifs.term.length
                ? "Please search"
                : "No gifs found"}
            </>
          )}
        </div>
      </OverlayScrollbarsComponent>

      // </div>
    );
  }
}

const mapStateToProps = ({ gifs }) => ({ gifs });

export default connect(mapStateToProps, { getGifs })(List);
