import React, { Component, createRef } from "react";
import randomcolor from "randomcolor";
//import scss
import "./Gif.scss";

class Gif extends Component {
  constructor(props) {
    super(props);

    this.gifRef = createRef();
  }

  state = { loading: true };

  //   componentDidMount() {
  //     console.log(this.gifRef.current.clientHeight);
  //   }
  render() {
    return (
      <div
        className={`df jcc aic gif_wrpr ${this.state.loading && "loading"}`}
        style={{
          height: `${this.props.gif.images.fixed_width_downsampled.height}px`,
          width: `${this.props.gif.images.fixed_width_downsampled.width}px`,
          top: this.props.gif.fromTop,
          left: this.props.gif.fromLeft,
          backgroundColor: randomcolor()
        }}
        // style={{
        //   height: this.gifRef.current && this.gifRef.current.clientHeight
        // }}
      >
        <img
          ref={this.gifRef}
          onLoad={() => {
            this.setState({ loading: false });
            this.props.updateDims(
              this.gifRef.current.clientHeight,
              this.props.i
            );
          }}
          src={this.props.gif.images.fixed_width_downsampled.url}
          alt={this.props.gif.title}
        ></img>
      </div>
    );
  }
}

export default Gif;
