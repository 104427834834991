import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { GET_GIF_LIST, GET_GIF_LIST_ERROR } from "../../store/actions/types";
import { api } from "../../hlelpers/api";

import "./searchBar.scss";
function SearchBar(props) {
  const [searchTerm, updateSearchTerm] = useState("");
  const [search, searchAction] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    api(
      `https://api.giphy.com/v1/gifs/search?q=${searchTerm}&api_key=hEbBGo4XgvvtIaRMoOZidM1cT9QM7AYO&limit=80&rating=G`
    ).then(async res => {
      if (res == "Error")
        return dispatch({
          type: GET_GIF_LIST_ERROR,
          payload: { term: searchTerm }
        });
      await res.data.data.map((itm, i) => {
        res.data.data[i].fromTop =
          i >= props.fitsInPage
            ? res.data.data[i - props.fitsInPage].fromTop +
              Number(
                res.data.data[i - props.fitsInPage].images
                  .fixed_width_downsampled.height
              )
            : 0;

        if (i < props.fitsInPage) {
          res.data.data[i].fromLeft = 200 * i;
        } else {
          res.data.data[i].fromLeft =
            res.data.data[i - props.fitsInPage].fromLeft;
        }

        return true;
      });

      dispatch({
        type: GET_GIF_LIST,
        payload: { ...res.data, term: searchTerm }
      });
    });
  }, [search]);

  return (
    <>
      <input
        className="search_inpt"
        placeholder="Press enter to search"
        type="text"
        value={searchTerm}
        onChange={async e => {
          //searchTerm.length === 0 && searchAction(!search);
          await updateSearchTerm(e.target.value);
        }}
        onKeyUp={e =>
          e.keyCode == 13
            ? searchAction(!search)
            : e.keyCode === 8 &&
              searchTerm.length === 0 &&
              searchAction(!search)
        }
      ></input>
      <button className="enter_btn" onClick={() => searchAction(!search)}>
        {"ENTER"}
      </button>
    </>
  );
}

export default SearchBar;
