import {
  GET_GIF_LIST,
  UPDATE_PAGE_WIDTH,
  GET_GIF_LIST_ERROR
} from "../actions/types";

export default (state = { list: [], term: "" }, action) => {
  switch (action.type) {
    case GET_GIF_LIST:
      return {
        ...state,
        ...action.payload,
        term: action.payload.term || state.term
      };

    case UPDATE_PAGE_WIDTH:
      return { ...state, pageWidth: action.payload };
    case GET_GIF_LIST_ERROR:
      return { ...state, ...action.payload, error: "Error getting gifs" };
    default:
      return state;
  }
};
