import React from "react";
import SearchBar from "../searchBar/searchBar";
import { useSelector } from "react-redux";

import "./Header.scss";
const Header = props => {
  const gifs = useSelector(state => state.gifs);
  return (
    <div className="df jcc hdr_wrpr">
      <div className="df jcc ">
        <SearchBar fitsInPage={props.fitsInPage}></SearchBar>
        {/* <button
          className="df load_more"
          disabled={
            gifs.data && gifs.data.length >= gifs.pagination.total_count
          }
          onClick={() => gifs.data.length && props.getGifs()}
        >
          Load more {props.fitsInPage}
        </button> */}
      </div>
      {/* {gifs.pagination && ( */}
      <p className="counts_wrpr">
        {gifs.pagination && gifs.data.length > 0 && (
          <>
            {gifs.data.length} of {gifs.pagination.total_count}
          </>
        )}
      </p>
      {/* )} */}
    </div>
  );
};

export default Header;
