import { GET_GIF_LIST, UPDATE_PAGE_WIDTH, GET_GIF_LIST_ERROR } from "./types";
import { api } from "../../hlelpers/api";

export const getGifs = (val, page) => async (dispatch, getState) => {
  const { gifs } = getState();

  let list = await api(
    `https://api.giphy.com/v1/gifs/search?q=${val ||
      gifs.term}&api_key=hEbBGo4XgvvtIaRMoOZidM1cT9QM7AYO&offset=${(gifs.data &&
      gifs.data.length) ||
      0}&limit=80&rating=G`
  );

  if (list == "Error")
    return dispatch({
      type: GET_GIF_LIST_ERROR,
      payload: {}
    });

  list.data.data = [...gifs.data, ...list.data.data];

  await list.data.data.map((itm, i) => {
    list.data.data[i].fromTop =
      i >= gifs.pageWidth
        ? list.data.data[i - gifs.pageWidth].fromTop +
          Number(
            list.data.data[i - gifs.pageWidth].images.fixed_width_downsampled
              .height
          )
        : 0;

    if (i < gifs.pageWidth) {
      list.data.data[i].fromLeft = 200 * i;
    } else {
      list.data.data[i].fromLeft = list.data.data[i - gifs.pageWidth].fromLeft;
    }

    return true;
  });
  dispatch({
    type: GET_GIF_LIST,
    payload: { ...list.data }
  });
  return true;
};

export const updatePageWidth = val => {
  return { type: UPDATE_PAGE_WIDTH, payload: val };
};
