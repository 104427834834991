import React, { Component } from "react";
import "./App.css";
import { connect } from "react-redux";
import { getGifs, updatePageWidth } from "./store/actions";

import Header from "./components/header/Header";
import List from "./components/GifList/GifList";
// import "overlayscrollbars/css/OverlayScrollbars.css";

// import Example from "./components/searchBar";

class App extends Component {
  constructor(props) {
    super(props);

    this.pageRef = React.createRef();
  }
  state = { gifList: [], dims: [] };

  handleResize = e => {
    this.setState(prevState => {
      let newVal = Math.floor(Number(this.pageRef.current.clientWidth / 200));

      if (newVal !== prevState.fitsInPage) this.props.updatePageWidth(newVal);
      return {
        fitsInPage: newVal
      };
    });
  };

  async componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    await this.setState({
      fitsInPage: Math.floor(Number(this.pageRef.current.clientWidth / 200))
    });
    this.props.updatePageWidth(this.state.fitsInPage);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  updateDims = (val, index) =>
    this.setState(prevState => {
      let tempDims = [...prevState.dims];
      tempDims[index] = val;
      return { dims: tempDims };
    });
  render() {
    return (
      <div className="App" ref={this.pageRef}>
        <Header
          fitsInPage={this.state.fitsInPage}
          getGifs={this.props.getGifs}
        ></Header>

        <List
          updateDims={this.updateDims}
          fitsInPage={this.state.fitsInPage}
        ></List>
      </div>
    );
  }
}

const mapStateToProps = ({ gifs }) => ({ gifs });

export default connect(mapStateToProps, { getGifs, updatePageWidth })(App);
